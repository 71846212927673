export const LOAD_SUB_CATEGORY = 'landers/Category/LOAD_SUB_CATEGORY'
export const LOAD_SUB_CATEGORY_SUCCESS =
  'landers/Category/LOAD_SUB_CATEGORY_SUCCESS'
export const LOAD_SUB_CATEGORY_FAIL = 'landers/Category/LOAD_SUB_CATEGORY_FAIL'

export const LOAD_PRODUCT_FILTERS = 'landers/Category/LOAD_PRODUCT_FILTERS'
export const LOAD_PRODUCT_FILTERS_SUCCESS =
  'landers/Category/LOAD_PRODUCT_FILTERS_SUCCESS'
export const LOAD_PRODUCT_FILTERS_FAILED =
  'landers/Category/LOAD_PRODUCT_FILTERS_FAILED'

export const LOAD_PRODUCT_CATEGORY = 'landers/Category/LOAD_PRODUCT_CATEGORY'
export const SAVE_PRODUCT_CATEGORY = 'landers/Category/SAVE_PRODUCT_CATEGORY'
export const LOAD_PRODUCT_LIST = 'landers/Category/LOAD_PRODUCT_LIST'
export const SAVE_PRODUCT_LIST = 'landers/Category/SAVE_PRODUCT_LIST'
export const CLEAR_PRODUCT_LIST = 'landers/Category/CLEAR_PRODUCT_LIST'
export const SAVE_SLUG_DATA = 'landers/Category/SAVE_SLUG_DATA'
export const CLEAR_SLUG_DATA = 'landers/Category/CLEAR_SLUG_DATA'
export const SAVE_SECONDARY_FILTER = 'landers/Category/SAVE_SECONDARY_FILTER'
export const SAVE_LIFE_STYLE_FILTER = 'landers/Category/SAVE_LIFE_STYLE_FILTER'
export const SAVE_PRICE_FILTER = 'landers/Category/SAVE_PRICE_FILTER'
export const SAVE_BRAND_FILTER = 'landers/Category/SAVE_BRAND_FILTER'
export const SAVE_FILTER = 'landers/Category/SAVE_FILTER'
export const SAVE_ARRANGE = 'landers/Category/SAVE_ARRANGE'

export const dropdownFilter = {
  options: [
    // { value: '', label: 'Popularity' },
    {
      label: 'Latest',
      value: null,
    },
    {
      value: 'price',
      label: 'Price Low',
    },
    {
      value: 'price_desc',
      label: 'Price High',
    },
    // {
    //   label: 'Discount Low to High',
    //   value: 'discount',
    // },
    {
      label: 'Discounted',
      value: 'discount_desc',
    },
    {
      value: 'name+',
      label: 'Name A-Z',
    },
    {
      label: 'Name Z-A',
      value: 'name-',
    },
    // {
    //   value: 'entity_id',
    //   label: 'Created Date',
    // },
  ],
}
