// Refer Enhanced Ecommerce Data Types and Actions: https://developers.google.com/analytics/devguides/collection/analyticsjs/enhanced-ecommerce#ecommerce-data

import get from 'lodash/get'
import dayjs from 'dayjs'
import { isMobile } from 'react-device-detect'

window.dataLayer = window.dataLayer || []

export const AppEvent = {
  npsSurvey: 'nps_survey',
}

const getAttributeValueByCode = (attributes, code) => {
  if (!Array.isArray(attributes)) {
    return ''
  }
  const attrObj = attributes.find(
    (attr) => attr && attr.attribute_code === code
  )
  return get(attrObj, 'value') || ''
}

const getProducts = (items, sourceTracking) => {
  if (!Array.isArray(items)) {
    return []
  }
  return items.map((item) => {
    const customAttr = get(item, 'custom_attributes') || []

    return {
      name: item.name,
      id: item.id,
      quantity: item.qty,
      price: item.price,
      brand: getAttributeValueByCode(customAttr, 'brand'),
      category: getAttributeValueByCode(customAttr, 'category'),
      dimension1: get(sourceTracking, 'source', ''),
      dimension2: get(sourceTracking, 'type', ''),
      dimension3: get(sourceTracking, 'element', ''),
      dimension4: get(sourceTracking, 'filter', ''),
    }
  })
}
const getProductsFromCart = (items) => {
  if (!Array.isArray(items)) {
    return []
  }
  return items.map((item) => {
    const customAttr =
      get(item, 'extension_attributes.product_data.custom_attributes') || []
    return {
      name: item.name,
      id: get(item, 'extension_attributes.product_data.id') || '',
      quantity: item.qty,
      price: item.price,
      brand: getAttributeValueByCode(customAttr, 'brand'),
      category: getAttributeValueByCode(customAttr, 'category'),
    }
  })
}

const getProductsFromOrder = (items) => {
  if (!Array.isArray(items)) {
    return []
  }
  return items.map((item) => ({
    name: item.name,
    id: `${item.product_id}`,
    price: item.price,
    quantity: item.qty_ordered,
    category: '',
    brand: '',
  }))
}

export const measureCheckout = (payload) => {
  const ecommerceData = {
    checkout: {
      actionField: payload.actionField,
      products: getProductsFromCart(payload.items),
    },
  }
  window.dataLayer.push({ event: 'checkout', ecommerce: ecommerceData })
}

export const measureCheckoutOption = (payload) => {
  const ecommerceData = {
    checkout_option: {
      actionField: {
        step: payload.actionField.step,
        option: payload.actionField.option,
      },
    },
  }

  window.dataLayer.push({ event: 'checkoutOption', ecommerce: ecommerceData })
}

export const measurePurchaseTransaction = (payload) => {
  const ecommerceData = {
    purchase: {
      actionField: {
        id: payload.increment_id, // Transaction ID. Required for purchases and refunds.
        affiliation: 'Landers Superstore',
        revenue: payload.grand_total, // Total transaction value (incl. tax and shipping)
        tax: payload.tax_amount,
        shipping: payload.shipping_amount,
        coupon: payload.coupon_code || '',
      },
      products: getProductsFromOrder(payload.items),
    },
  }
  window.dataLayer.push({
    event: 'transaction',
    ecommerce: ecommerceData,
  })
}

export const measureAddProductToCart = (payload, sourceTracking) => {
  const ecommerceData = {
    currencyCode: 'PHP',
    add: {
      products: getProducts(payload.items, sourceTracking),
    },
  }
  window.dataLayer.push({
    event: 'addToCart',
    ecommerce: ecommerceData,
  })
}

export const measureRemoveProductFromCart = (payload) => {
  const ecommerceData = {
    currencyCode: 'PHP',
    remove: {
      products: getProducts(payload.items),
    },
  }
  window.dataLayer.push({
    event: 'removeFromCart',
    ecommerce: ecommerceData,
  })
}

export const measureSplashAd = (payload) => {
  window.dataLayer.push({
    event: 'Splash Ads',
    action: payload.action,
    link_to: payload.link_to,
  })
}

export const measureNotification = (payload, eventName) => {
  window.dataLayer.push({
    event: eventName,
    platform: payload.platform,
    title: payload.title,
  })
}

const formatTrackingDate = (date) => {
  if (!date) {
    return ''
  }
  const now = new Date()
  const timeslotDate = new Date(date)
  const daysTo = timeslotDate.getDate() - now.getDate()
  if (daysTo < 0) {
    return ''
  }
  return daysTo > 0 ? `n+${daysTo}` : 'n'
}

const formatTrackingPeriod = (period, date) => {
  if (!period || typeof period !== 'string') {
    return ''
  }
  let [start, end] = period.split('-').map((item) => item.trim())
  if (!start && !end) {
    return ''
  }
  start = dayjs(date + start).format('HHmm')
  end = dayjs(date + end).format('HHmm')
  return `${start}-${end}`
}

export const trackDeliveryTimeslotClick = (payload) => {
  const { event, period, date, store } = payload
  if (!event || !period || !store || !date) {
    return
  }
  const timeslot = `${formatTrackingDate(date)}_${formatTrackingPeriod(
    period,
    date
  )}`
  window.dataLayer.push({
    event,
    timeslot,
    store,
  })
}

export const trackEvent = (event, payload) => {
  window.dataLayer.push({
    event: event,
    time: new Date().getTime(),
    platform: isMobile ? 'msite' : 'web',
    ...payload,
  })
}
