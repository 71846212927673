import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import config from 'global-config'

const baseQueryInterceptor = async (args, api, extraOptions) => {
  const baseUrl = config.npsUrl
  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      headers.set('Authorization', `Bearer ${config.snapMartApiLongToken}`)
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')

      return headers
    },
  })

  const result = await baseQuery(args, api, extraOptions)

  // @TODO status should be 401 here waiting for microservice to fix this
  if (result.error?.status === 'FETCH_ERROR') {
    // @TODO should we retry the request ?
  }

  return result
}

export const snapmartApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: 'snapmartApi',
})
