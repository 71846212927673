import axios from 'axios'

import { getAccessToken } from './request'
import config from 'global-config'
import { store } from 'configureStore'
import { getCurrentUser } from 'utils/validate'
import { setLambdaAccessToken } from 'containers/Authentication/actions'

let nodeToken = ''
let expiresIn = ''

export function setNodeToken(token) {
  nodeToken = token
}

export function getNodeToken() {
  return nodeToken
}

export function setExpiry(expires_in) {
  expiresIn = expires_in
}

export function getExpiry() {
  return expiresIn
}

export const isTokenExpired = () => {
  // Check if the expiration date is less than 1 hour from the current date
  let isLessThanOneHour = true

  if (expiresIn !== '') {
    const oneHourInMilliseconds = 60 * 60 * 1000
    const currentDate = new Date()
    let expirationDate = new Date(expiresIn * 1000)
    isLessThanOneHour = expirationDate - currentDate < oneHourInMilliseconds
  }

  return isLessThanOneHour
}

const NOT_AUTH_API_PATHS = ['/login', '/guest-carts']

const instance = axios.create({
  headers: {},
})

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

instance.interceptors.response.use(undefined, async (error) => {
  const statusCode = error.request.status
  const { config } = error

  if (getAccessToken() === '') {
    return Promise.reject(error)
  }

  if (!config || !config.retry) {
    return Promise.reject(error)
  }

  config.retry -= 1

  const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
    (path) => !config.url.includes(path),
  )

  if (
    ((error.code === 'ERR_NETWORK' && statusCode === 0) ||
      statusCode === 401) &&
    isNotAuthAPI &&
    getAccessToken() !== ''
  ) {
    await delay(1500)
    await reAuth()
    return instance(config)
  }

  return Promise.reject(error)
})

instance.interceptors.request.use(
  async (config) => {
    const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
      (path) => !config.url.includes(path),
    )

    if (isNotAuthAPI) {
      if (isTokenExpired() && getAccessToken() !== '') {
        await delay(1500)
        await reAuth()
      }
    }

    config.headers = {
      ...config.headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getNodeToken()}`,
    }
    return config
  },
  (error) => {
    return Promise.reject(error)
  },
)

const reAuth = async () => {
  const currentUser = getCurrentUser()
  const requestURL = `${config.lambdaURL.user}/login`

  try {
    const { data } = await instance.post(requestURL, {
      access: getAccessToken(),
      email: currentUser.email,
    })

    setNodeToken(data.data.access_token)
    setExpiry(data.data.expires_in)
    store.dispatch(setLambdaAccessToken(data.data))
  } catch (error) {}
}

export function req(url, options) {
  const defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  // Merge options with default headers, giving priority to custom headers
  const mergedHeaders = Object.assign({}, defaultHeaders, options.headers)

  // Create the final options object with merged headers
  const parsedOptions = Object.assign({}, options, { headers: mergedHeaders })

  return instance({
    url,
    retry: 1,
    ...parsedOptions,
  })
}
