import { produce } from 'immer'
import localStorage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'

import * as constants from './constants'
import { LOGOUT } from 'containers/Landers/constants'

const persistConfig = {
  key: 'account',
  storage: localStorage,
  whitelist: ['allAddresses', 'trackPopupDrawer'],
  debounce: 3000,
}

export const initialState = {
  statusEditAbleAddress: {
    isSuccess: false,
    error: '',
    isSubmitting: false,
  },
  isUpdatingUser: false,
  allOrders: {},
  currentOrders: {},
  priceSubscriptions: [],
  stockSubscriptions: [],
  account: {},
  productReviewData: [],
  listCountNotifications: [],
  processingOrders: 0,
  trackPopupDrawer: {
    isAlreadyShown: false,
    latestItem: {},
  },
}

const reducer = (state = initialState, action) =>
  produce(state, (newState) => {
    switch (action.type) {
      // GET_All_ORDERS
      case constants.GET_All_ORDERS:
        break
      case constants.GET_All_ORDERS_SUCCESS:
        newState.allOrders = action.payload
        break
      case constants.GET_All_ORDERS_FAILED:
        break

      // GET_CURRENT_ORDERS
      case constants.GET_CURRENT_ORDERS:
        break
      case constants.GET_CURRENT_ORDERS_SUCCESS:
        newState.currentOrders = action.payload
        break
      case constants.GET_CURRENT_ORDERS_FAILED:
        break
      case constants.GET_COUNT_PROCESSING_ORDERS_SUCCESS:
        newState.processingOrders = action.payload
        break

      case constants.SET_POPUP_TRACKER_DRAWER:
        newState.trackPopupDrawer = {
          ...newState.trackPopupDrawer,
          ...action.payload,
        }
        break

      // CREATE_ADDRESS
      case constants.CREATE_ADDRESS:
        newState.statusEditAbleAddress = {
          isSubmitting: true,
        }
        break
      case constants.CREATE_ADDRESS_SUCCESS:
        newState.statusEditAbleAddress = {
          isSuccess: true,
          isSubmitting: false,
        }
        break
      case constants.CREATE_ADDRESS_FAILED:
        newState.statusEditAbleAddress = {
          error: action.error,
          isSubmitting: false,
        }
        break
      case constants.CLEAR_STATUS_EDITABLE_ADDRESS:
        newState.statusEditAbleAddress = {}
        break

      // UPDATE_ADDRESS
      case constants.UPDATE_ADDRESS:
        newState.statusEditAbleAddress = {
          isSubmitting: true,
        }
        break
      case constants.UPDATE_ADDRESS_SUCCESS:
        newState.statusEditAbleAddress = {
          isSuccess: true,
          isSubmitting: false,
        }
        break
      case constants.UPDATE_ADDRESS_FAILED:
        newState.statusEditAbleAddress = {
          error: action.error,
          isSubmitting: false,
        }
        break

      //UPDATE_USER_INFO
      case constants.UPDATE_USER_INFO:
        newState.isUpdatingUser = true
        break
      case constants.UPDATE_USER_INFO_SUCCESS:
        newState.isUpdatingUser = false
        break
      case constants.UPDATE_USER_INFO_FAILED:
        newState.isUpdatingUser = false
        break

      //UPDATE_EMAIL
      case constants.UPDATE_EMAIL:
        newState.isUpdatingUser = true
        break
      case constants.UPDATE_EMAIL_SUCCESS:
        newState.isUpdatingUser = false
        break
      case constants.UPDATE_EMAIL_FAILED:
        newState.isUpdatingUser = false
        break

      //UPDATE_PASSWORD
      case constants.UPDATE_PASSWORD:
        newState.isUpdatingUser = true
        break
      case constants.UPDATE_PASSWORD_SUCCESS:
        newState.isUpdatingUser = false
        break
      case constants.UPDATE_PASSWORD_FAILED:
        newState.isUpdatingUser = false
        break

      // GET PRICE SUBSCRIPTIONS
      case constants.GET_PRICE_SUBSCRIPTIONS:
        break
      case constants.GET_PRICE_SUBSCRIPTIONS_SUCCESS:
        newState.priceSubscriptions = action.payload
        break
      case constants.GET_PRICE_SUBSCRIPTIONS_FAILED:
        break

      // GET STOCK SUBSCRIPTIONS
      case constants.GET_STOCK_SUBSCRIPTIONS:
        break
      case constants.GET_STOCK_SUBSCRIPTIONS_SUCCESS:
        newState.stockSubscriptions = action.payload
        break
      case constants.GET_STOCK_SUBSCRIPTIONS_FAILED:
        break

      // product review
      case constants.LOAD_PRODUCT_REVIEW:
        break
      case constants.SAVE_PRODUCT_REVIEW:
        newState.productReviewData = action.payload
        break

      case LOGOUT:
        newState.trackPopupDrawer.isAlreadyShown = false
        newState.trackPopupDrawer.latestItem = {}
        break

      default:
        break
    }
  })

export default persistReducer(persistConfig, reducer)
