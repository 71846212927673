import { createBrowserHistory } from 'history'
import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { routerMiddleware } from 'connected-react-router'
import createRootReducer from './mainReducer'
import createSagaMiddleware from 'redux-saga'
import { setAutoFreeze } from 'immer'
import { persistStore } from 'redux-persist'
import rootSaga from 'store/saga'
import { coreApi } from 'api/coreApi'
import { snapmartApi } from 'api/snapmartApi'
import npsListenerMiddleware from 'containers/Home/slices/nps/npsListener'
import { mgtApi } from 'api/mgtApi'

import {
  renewMayaListenerMiddleware,
  submitMayaInfoListenerMiddleware,
  showMayaXLandersMiddleware,
  signUpLoginWithMayaCCMiddleware,
} from 'containers/Modals/slices/renewMaya'

setAutoFreeze(false)

const sagaMiddleware = createSagaMiddleware()

export const history = createBrowserHistory()
const store = configureStore({
  reducer: createRootReducer(history),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      routerMiddleware(history),
      sagaMiddleware,
      coreApi.middleware,
      snapmartApi.middleware,
      npsListenerMiddleware.middleware,
      mgtApi.middleware,
      renewMayaListenerMiddleware.middleware,
      submitMayaInfoListenerMiddleware.middleware,
      showMayaXLandersMiddleware.middleware,
      signUpLoginWithMayaCCMiddleware.middleware,
    ),
})

setupListeners(store.dispatch)
sagaMiddleware.run(rootSaga)
const persistor = persistStore(store)

export { store, persistor }
