let nodeToken = ''
let expiresIn = ''
let userEmail = ''

export function setNodeTokenApi(token) {
  nodeToken = token
}

export function getNodeTokenApi() {
  return nodeToken
}

export function setExpiryApi(expires_in) {
  expiresIn = expires_in
}

export function getExpiryApi() {
  return expiresIn
}

export function setUserEmailApi(email) {
  userEmail = email
}

export function getUserEmailApi() {
  return userEmail
}

export const isTokenExpired = () => {
  // Check if the expiration date is less than 1 hour from the current date
  let isLessThanOneHour = true

  if (expiresIn !== '') {
    const oneHourInMilliseconds = 60 * 60 * 1000
    const currentDate = new Date()
    let expirationDate = new Date(expiresIn * 1000)
    isLessThanOneHour =
      expirationDate - currentDate < oneHourInMilliseconds * 14 // check if expiration of token is less than 14 hours
  }

  return isLessThanOneHour
}
