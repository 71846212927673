import { createListenerMiddleware } from '@reduxjs/toolkit'
import npsApi from 'api/nps/nps'
import { initializeNPS } from './slice'
import { loadNetPromotersScoreSurvey } from 'containers/Home/actions'
import {
  setForNpsCompletedOrders,
  setCurrentNpsDisplay,
} from 'containers/Home/slices/nps/slice'
import { cloneDeep, isEqual } from 'lodash'
// Create the middleware instance and methods
const npsListenerMiddleware = createListenerMiddleware()
npsListenerMiddleware.startListening({
  actionCreator: initializeNPS,
  effect: async (action, listenerApi) => {
    try {
      const { nps_url_value, nps_type_value, order_no } = action.payload
      let currentNpsDisplay = cloneDeep(
        listenerApi.getState()?.npsSlice?.currentNpsDisplay,
      )

      const forNpsCompletedOrders =
        listenerApi.getState()?.npsSlice?.forNpsCompletedOrders

      const timestampRef = new Date()
      let { data } = await listenerApi
        .dispatch(
          npsApi.endpoints.homePageRatingOrder.initiate({
            userId: action.payload.userId,
            timestampRef: timestampRef,
          }),
        )
        .unwrap()

      if (order_no) {
        data = [order_no]
      }

      const areEqual = isEqual(forNpsCompletedOrders, data)

      if (!areEqual) {
        listenerApi.dispatch(setForNpsCompletedOrders(data ? data : []))
        listenerApi.dispatch(setCurrentNpsDisplay(0))
        currentNpsDisplay = 0
      }

      let order_no_value = ''
      if (data.length - 1 >= currentNpsDisplay) {
        order_no_value = data[currentNpsDisplay]
      }
      const payload = {
        url: nps_url_value,
        type: nps_type_value,
        orderNumber: order_no_value,
      }
      listenerApi.dispatch(loadNetPromotersScoreSurvey({ data: payload }))
    } catch (error) {}
  },
})
export default npsListenerMiddleware
