import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import { isEmpty } from 'lodash'

import {
  isTokenExpired,
  getNodeTokenApi,
  setNodeTokenApi,
  setExpiryApi,
  getUserEmailApi,
} from 'api/utils'
import { getCurrentUser } from 'utils/validate'
import { store } from 'configureStore'
import { getAccessToken } from 'utils/request'
import { setLambdaAccessToken } from 'containers/Authentication/actions'

const baseUrl = process.env.REACT_APP_LAMBDA_CORE_MS_URL

const NOT_AUTH_API_PATHS = ['/login', '/guest-carts']

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

const mutex = new Mutex()

const reAuth = async (api, extraOptions) => {
  if (!mutex.isLocked()) {
    const release = await mutex.acquire()

    let email = getUserEmailApi()

    if (isEmpty(email)) {
      await delay(1000)
      const currentUser = getCurrentUser()
      email = currentUser.email
    }

    try {
      const authQuery = fetchBaseQuery({
        baseUrl,
        prepareHeaders: async (headers) => {
          headers.set('Accept', 'application/json')
          headers.set('Content-Type', 'application/json')

          return headers
        },
      })

      const { data } = await authQuery(
        {
          url: '/user/login',
          method: 'POST',
          body: { access: getAccessToken(), email },
        },
        api,
        extraOptions,
      )

      if (data?.data) {
        setNodeTokenApi(data.data.access_token)
        setExpiryApi(data.data.expires_in)
        store.dispatch(setLambdaAccessToken(data?.data))
      }
    } finally {
      release()
    }
  }
}

const baseQueryInterceptor = async (args, api, extraOptions) => {
  await mutex.waitForUnlock()

  const isNotAuthAPI = NOT_AUTH_API_PATHS.every(
    (path) => !args.url.includes(path),
  )

  if (isNotAuthAPI && isTokenExpired()) {
    await reAuth(api, extraOptions)
  }

  await mutex.waitForUnlock()

  const baseQuery = fetchBaseQuery({
    baseUrl,
    prepareHeaders: async (headers) => {
      headers.set('Authorization', `Bearer ${getNodeTokenApi()}`)
      headers.set('Accept', 'application/json')
      headers.set('Content-Type', 'application/json')

      return headers
    },
  })

  let result = await baseQuery(args, api, extraOptions)

  // @TODO status should be 401 here waiting for microservice to fix this
  if (result.error?.status === 'FETCH_ERROR') {
    await reAuth(api, extraOptions)
    await mutex.waitForUnlock()
    result = await baseQuery(args, api, extraOptions)
  }

  return result
}

// initialize an empty api service that we'll inject endpoints into later as needed
export const coreApi = createApi({
  baseQuery: baseQueryInterceptor,
  endpoints: () => ({}),
  reducerPath: 'coreApi',
})
