const api = process.env.REACT_APP_API_URL + '/rest/V1/'

const image = process.env.REACT_APP_IMAGE_URL

export default {
  api: api,
  image: image,

  account: '/customer/account',
  notFound: '/not-found',
  checkoutCart: '/checkout/cart',
  checkout: '/checkout',
  checkoutDelivery: '/checkout-delivery',
  checkoutPaymentMethod: '/checkout-payment-method',
  checkoutSuccess: '/checkout/onepage/success',
  checkoutFailure: '/checkout/onepage/failure',
  internalServerError: '/internal-server-error',
  maintenance: '/maintenance',
  home: '/',
  membership: '/lofcustomermembership/buy',
  help: '/help-center',
  myMemberShip: '/my-membership',
  theLanderExperience: '/the-landers-experience',
  career: '/career',
  specialProduct: '/special-products.html',
  // searchResult: '/search-result',

  // footer - help
  faq: '/help-center',
  contactUs: '/help-center/contact-us',
  howToApplyMembership: '/how-to-apply-membership',
  howToCreateShoppingLists: '/how-to-create-shopping-lists',
  howToShopOnline: '/how-to-shop-online',
  howToUseVoucher: '/how-to-use-voucher',
  landersSuperstorePrivacyStatement: '/landers-superstore-privacy-statement',
  termsAndConditions: '/terms-and-conditions',
  facebookPage: 'https://www.facebook.com/LandersPH/',
  youtubeChanel: 'https://www.youtube.com/channel/UC-35-er6ht5owbxt_uStfsw',
  instagramPage: 'https://www.instagram.com/landersph/',
  deliveryAreas: '/delivery-areas',

  // Order link
  order: '/sales/order/history',
  orderView: '/sales/order/view/order_id/:orderId',
  orderInvoice: '/sales/order/invoice/order_id/:orderId',
  printOrder: '/sales/order/print/order_id/:orderId',
  printInvoice: '/sales/order/printInvoice/invoice_id/:orderId',
  trackingOrder: '/track-and-trace/track/:increment_id',
  tracingOrder: '/track-and-trace/trace/:increment_id',
  trackingPackage: '/track-and-trace/package/:packageId',
  trackAndTrace: '/track-and-trace',

  // account links
  wishlist: '/wishlist',
  addressBook: '/customer/address',
  paymentMethods: '/customer/payment-methods',
  createAddressBook: '/customer/address/new',
  editAddressBook: '/customer/address/:id',
  editAccount: '/customer/account/edit',
  createPassword: '/customer/account/createpassword',
  shoppingList: '/shopping-list',
  list: '/shopping-list/list',
  allProductsInList: '/all-products-in-list',
  myReview: '/my-review',
  priceSubcription: '/price-subscription',
  outOfStock: '/out-of-stock-subscription',

  // Search
  search: '/catalogsearch',
  searchResult: '/catalogsearch/result/',
  notification: '/notification',

  // Chat Plugin
  chatPlugin: '/chat-plugin',
  productDetailView: '/:category/:productslug',
}
